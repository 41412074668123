import "./ShopTypeSwitchButton.css"

const ShopTypeSwitchButton = () =>{
    return(
        <>
            <span className="switch">
                <input type="checkbox" id="switcher"></input>
                <label for="switcher"></label>
            </span>
            
        </>
    )
}
export default ShopTypeSwitchButton;